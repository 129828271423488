import '../../assets/ModalStyle/ModalStyle.css';

import { EmailValidation } from '../../utils/functions/EmailValidation';
import Modal from '../Modal/Modal';
import ModalMessage from '../ModalMessage/ModalMessage';
import { NameValidation } from '../../utils/functions/NameValidation';
import { ReactComponent as TrueCircle } from '../../assets/icons/TrueCircle.svg';
import { api } from '../../utils/api/api';
import { useState } from 'react';

function AddUsersModal({ closeModal }) {
  const [showModal, setShowModal] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorName, setErrorName] = useState(false);



  

  const form = {
    nome: '',
    email: '',
    // password: '',
  };

  const [stateUser, setStateUser] = useState(form);

  const handleChange = (e, name) => {
    if (name === 'name') {
      setErrorName(false);
    }
    if (name === 'email') {
      setErrorEmail(false);
    }
    setStateUser({ ...stateUser, [name]: e.target.value });
  };

  // const navigate = useNavigate();

  function handleCancel() {
    closeModal();
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const UserPayload = {
      name: e.currentTarget.name.value,
      email: e.currentTarget.email.value,
      // password: '',
      // isActive: true,
    };

    const isNameValid = NameValidation(UserPayload.name);
    const isEmailValid = EmailValidation(UserPayload.email);

    if (!isEmailValid) {
      setErrorEmail(true);
    }
    if (!isNameValid) {
      setErrorName(true);
    }

    if (isEmailValid && isNameValid) {
      const userData = await api.createUser(UserPayload);
      if (userData) {
        setShowModal(true);
      } else {
      }
    }
  }

  return (
    <>
      {showModal ? (
        <Modal closeModal={closeModal}>
          <ModalMessage
            icon={<TrueCircle size={40} />}
            title="Usuário criado com sucesso"
            message="O usuário cadastrado receberá um e-mail para cadastrar a senha "
            button="Entendido"
            onClick={handleCancel}
            closeModal={closeModal}
          />
        </Modal>
      ) : (
        <Modal closeModal={closeModal}>
          <div>
            <form autoComplete="off" className="form" onSubmit={handleSubmit}>
              <h2 className="h2_Modal">Novo Usuário</h2>

              <div>
                <label htmlFor="name" className="label_Modal">
                  Nome *
                  {errorName === true && (
                    <span className="error_span">
                      ( Nome deve ter mais que 3 caracteres)
                    </span>
                  )}
                </label>
              </div>
              <div>
               
                  <input
                    className="input_Modal"
                    id="name"
                    type="text"
                    placeholder="Digite o seu nome."
                    value={stateUser.name}
                    onChange={(e) => handleChange(e, 'name')}
                    style={errorName? { border: "solid 1px red" }:{}}
                    
                  />
               
              </div>
              <div>
                <label htmlFor="email" className="label_Modal">
                  E-mail *
                  {errorEmail === true && (
                    <span className="error_span">(E-mail Inválido)</span>
                  )}
                </label>
              </div>
              <div>
                {errorEmail ? (
                  <input
                    className="input_Modal"
                    id="email"
                    type="text"
                    placeholder="Digite aqui seu E-mail."
                    value={stateUser.email}
                    onChange={(e) => handleChange(e, 'email')}
                    style={{ border: 'solid 1px red' }}
                    
                  />
                ) : (
                  <input
                    className="input_Modal"
                    id="email"
                    type="text"
                    placeholder="Digite aqui seu E-mail."
                    value={stateUser.email}
                    onChange={(e) => handleChange(e, 'email')}
                  
                  />
                )}
              </div>

              <button type="submit" className="btn_Modal">
                Cadastrar usuário
              </button>

              <button
                type="submit"
                className="btn_Cancel_Modal"
                onClick={handleCancel}
              >
                Cancelar
              </button>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
}

export default AddUsersModal;
