import './Aside.css';

import React, { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Cam } from '../../assets/icons/Cam.svg';
import { ReactComponent as Config } from '../../assets/icons/Config.svg';
import { ReactComponent as Home } from '../../assets/icons/Home.svg';
import { ReactComponent as Report } from '../../assets/icons/Report.svg';
import { ReactComponent as Users } from '../../assets/icons/Users.svg';

function Aside() {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split('/');

  return (
    <aside className="menu">
      <ul>
        <li className={splitLocation[1] === '' ? 'active' : ''}>
          <Link to="/">
            <div className="icon">
              <Home />
            </div>
            Home
          </Link>
        </li>

        <li className={splitLocation[1] === 'transmissions' ? 'active' : ''}>
          <Link to="/transmissions">
            <div className="icon">
              <Cam />
            </div>
            Transmissões
          </Link>
        </li>

        <li className={splitLocation[1] === 'users' ? 'active' : ''}>
          <Link to="/users">
            <div className="icon">
              <Users />
            </div>
            Usuários
          </Link>
        </li>

        <li className={splitLocation[1] === 'report' ? 'active' : ''}>
          <Link to="/report">
            <div className="icon">
              <Report />
            </div>
            Relatórios
          </Link>
        </li>

        <li className={splitLocation[1] === 'config' ? 'active' : ''}>
          <Link to="/config">
            <div className="icon">
              <Config />
            </div>
            Configurações
          </Link>
        </li>
      </ul>
    </aside>
  );
}

export default Aside;
