import "../../assets/ModalStyle/ModalStyle.css";
import "./ScheduleTransmissionModal.css";
import "./DateTimePickerStyle.css";
import "moment/locale/pt";

import { useRef, useState } from "react";

import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import Datetime from "react-datetime";
import Modal from "../Modal/Modal";
import { NameValidation } from "../../utils/functions/NameValidation";
import { api } from "../../utils/api/api";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ScheduleTransmissionModal = ({ closeModal }) => {
  const scheduleDateTime = useRef();
  const [date, setDate] = useState("");
  const [currentDateTime, setcurrentDateTime] = useState(null);
  const [nameTransmission, setNameTransmission] = useState("");
  const [passwordTransmission, setPasswordTransmission] = useState("");
  const [errorName, setErrorName] = useState(false);

  const navigate = useNavigate();
  function handleCancel() {
    closeModal();
  }

  let inputProps = {
    placeholder: "Digite a data e a hora",
  };

  // datetime cancela data slecionada antiga
  var yesterday = moment().subtract(1, "day");
  var valid = function (current) {
    return current.isAfter(yesterday);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    const isNameValid = NameValidation(nameTransmission);
    console.log(isNameValid);

    if (!isNameValid) {
      setErrorName(true);
    } else {
      const scheduleTransmissionPayload = {
        name: nameTransmission,
        description: "",
        date: date.format("YYYY-MM-DD HH:mm:00"),
        password: passwordTransmission,
      };
      const scheduleTransmissionData = await api.createDateTransmission(
        scheduleTransmissionPayload
      );
      if (scheduleTransmissionData) {
        navigate("/transmissions");
      }
    }

    // console.log(scheduleTransmissionPayload);
  }

  function renderView(mode, renderDefault) {
    if (mode === "time") return renderDefault();

    return (
      <>
        {renderDefault()}
        <div className="controls">
          <button onClick={() => scheduleDateTime.current?._closeCalendar()}>
            Fechar
          </button>
        </div>
      </>
    );
  }

  return (
    <Modal closeModal={closeModal}>
      <div>
        <form autoComplete="off" className="form" onSubmit={handleSubmit}>
          <h2 className="h2_Modal">Agendar Transmissão</h2>
          <div>
            <label htmlFor="name" className="label_Modal">
              Nome da sala *
              {errorName === true && (
                <span className="error_span">
                  ( Nome deve ter mais que 3 caracteres)
                </span>
              )}
            </label>
          </div>
          <div>
            <input
              className="input_Modal"
              id="nameTransmission"
              name="nameTransmission"
              type="text"
              placeholder="Digite o nome da sala."
              onChange={(e) => {
                setNameTransmission(e.target.value);
                setErrorName(false);
              }}
              style={errorName ? { border: "solid 1px red" } : {}}
              autoComplete="off"
            />
          </div>
          <div>
            <label htmlFor="password" className="label_Modal">
              Senha <span className="txt_opcional">(opcional)</span>
            </label>
          </div>
          <div>
            <input
              className="input_Modal"
              id="passwordTransmission"
              name="passwordTransmission"
              type="password"
              placeholder="Digite sua senha."
              onChange={(e) => setPasswordTransmission(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div>
            <label htmlFor="date" className="label_Modal">
              Data da transmissão *
            </label>
          </div>
          <div className="icone_agendar">
            <Datetime
              locale="pt"
              isValidDate={valid}

              value={currentDateTime}

              ref={scheduleDateTime}
              className="input_Datetime"
              inputProps={inputProps}

              onChange={setcurrentDateTime}
              // onChange={(date) => setDate(date) }

              renderView={(mode, renderDefault) =>
                renderView(mode, renderDefault)
              }
            ></Datetime>

            <div
              className="icondata"

              onClick={() => setcurrentDateTime(new Date())}
              
            >
              <Calendar
                onClick={() => scheduleDateTime.current._openCalendar()}
              />
            </div>
          </div>

          <button type="submit" className="btn_Modal">
            Agendar
          </button>

          <button
            type="submit"
            className="btn_Cancel_Modal"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ScheduleTransmissionModal;
