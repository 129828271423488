import './AddUsers.css';
import { ReactComponent as UsersPlus } from '../../assets/icons/UsersPlus.svg';

function AddUsers({ adduser }) {
  return (
    <button className="transmissao3" type="button" onClick={() => adduser()}>
      <UsersPlus />
    </button>
  );
}

export default AddUsers;
