import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Login from '../pages/Login/Login';
import React from "react";
import Studio from '../pages/Studio/Studio';

const OtherRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='login' element={<Login />} />
        <Route path='studio/:id' element={<Studio />} />
        <Route path='*' element={<Navigate to='/login' />} />
      </Routes>
    </BrowserRouter>

  );
};

export default OtherRoutes;
