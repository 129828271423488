import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Account from "../pages/Account/Account";
import Config from '../pages/Config/Config';
import Home from '../pages/Home/Home'
import Login from '../pages/Login/Login';
import React from "react";
import Report from '../pages/Report/Report';
import Studio from '../pages/Studio/Studio';
import Transmissions from '../pages/Transmissions/Transmissions';
import Users from '../pages/Users/Users';

const SignRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='studio/:id' element={<Studio />} />
        <Route path='transmissions' element={<Transmissions />} />
        <Route path='users' element={<Users />} />
        <Route path='report' element={<Report />} />
        <Route path='config' element={<Config />} />
        <Route path='account' element={<Account />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>

  );
};

export default SignRoutes;
