import styled from 'styled-components';


// Escolhas dos tipos de telas para transmissão

export const BoxStream = styled.div`
  display: flex;
  justify-content: center;
  gap: .3rem;
  margin: 10px;
  margin-top: 15px;
`;

export const Stream = styled.div`
  width: 74.97px;
  height: 49.98px;
  top: 460.48px;
  border-radius: 4.54371px;
  cursor: pointer;
`;