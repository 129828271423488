// import './ScheduleTransmissionList.css'

import './ScheduleTransmissionList.css'

import { AiOutlineUser } from "react-icons/ai";
import { FormatData } from "../../utils/functions/FormatData";
import {ReactComponent as LogoutCinza} from '../../assets/icons/LogoutCinza.svg';
import {ReactComponent as Reportcinza} from '../../assets/icons/Reportcinza.svg';
import { Table } from "../Table/style";

const ScheduleTransmissionList = ({streamings}) => {
  return (
    <>
      <h2 className='list_title'>Transmissões</h2>
      <Table>
        <thead>
            <tr>
                <th>Nome</th>
                <th>Data</th>
                <th>Duração</th>
                <th>Participantes</th>
                <th>Status</th>
                <th>Ações</th>
            </tr>
            <tr>
                <td className="list_empty"></td>
            </tr>
        </thead>
        
        {streamings.map((streaming) => (
          <tbody>
            <tr>
              <td className='list_icon'><AiOutlineUser size={20} /> {streaming.name}</td>
              <td>{FormatData(streaming.createdAt)}</td>
              <td>Duração</td>
              <td className='list_icon'><AiOutlineUser size={20} /> Participantes</td>
    <td>Status</td>
    <td className='list_icon'><LogoutCinza/><Reportcinza/></td>
            </tr>
          </tbody>
        ))}   

      </Table>
    
    </>
  );
};

export default ScheduleTransmissionList;
