import LoginMain from '../../components/LoginMain/LoginMain';
import live_sim_logo_branco from '../../assets/logo/live_sim_logo_branco.png';
import './Login.css';

const Login = () => {
    return (
        <>
            <div className='home' />
            <div className='container'>
                <div className='logo'>
                    <img src={live_sim_logo_branco} className='img_logo' alt='logo' />
                </div>
                <LoginMain />
            </div>
        </>
    );
};

export default Login;
