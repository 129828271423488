import React, { useContext } from 'react';
import './LoginMain.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BoxRecoverPassword from '../BoxRecoverPassword/BoxRecoverPassword';
import { EmailValidation } from '../../utils/functions/EmailValidation';
import ModalMessage from '../ModalMessage/ModalMessage';
import Modal from '../Modal/Modal';

import { IoIosCloseCircleOutline } from 'react-icons/io';
import { api } from '../../utils/api/api';
import { GlobalContext } from '../../contexts/GlobalContext';

const LoginMain = () => {
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [errorEmail, setErrorEmail] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const { setUserData } = useContext(GlobalContext)

    const navigate = useNavigate();

    const form = {
        email: '',
        password: '',
    };

    const [stateLogin, setStateLogin] = useState(form);

    function handleClick() {
        setIsActive(!isActive);
    }
    async function handleSubmit(event) {
        event.preventDefault();

        const LoginPayload = {
            email: event.currentTarget.email.value,
            password: event.currentTarget.password.value,
        };

        const isEmailValid = EmailValidation(LoginPayload.email);
        if (!isEmailValid) {
            setErrorEmail(true);
        }

        if (isEmailValid) {
            const userData = await api.login(LoginPayload);
            if (!userData) {
                setError(true);
                return;
            }
            else {
                setUserData()
                navigate('/')
            }
        }
    }

    const handleChange = (e, name) => {
        if (name === 'email') {
            setErrorEmail(false);
        }
        setStateLogin({ ...stateLogin, [name]: e.target.value });
    };

    return (
        <>
            {isActive ? (
                <BoxRecoverPassword />
            ) : (
                <div className='boxlogin'>
                    <h1>Entre agora</h1>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor='email'>Email</label>
                        </div>
                        {errorEmail === true && (
                            <span className='error_span'>(E-mail Inválido)</span>
                        )}
                        {errorEmail ? (
                            <input
                                id='email'
                                type='text'
                                placeholder='Digite aqui seu e-mail cadastrado'
                                value={stateLogin.email}
                                onChange={(e) => handleChange(e, 'email')}
                                style={{ border: 'solid 1px red' }}
                                required
                            />
                        ) : (
                            <input
                                id='email'
                                type='text'
                                placeholder='Digite aqui seu e-mail cadastrado'
                                value={stateLogin.email}
                                onChange={(e) => handleChange(e, 'email')}
                                required
                            />
                        )}
                        {/* <input
              id='email'
              type='text'
              placeholder='Digite aqui seu e-mail cadastrado'
              value={stateLogin.email}
              onChange={(e) => handleChange(e, 'email')}
              required
            /> */}
                        <div>
                            <label htmlFor='password'>Senha</label>
                        </div>
                        <input
                            id='password'
                            label='Senha'
                            type='password'
                            value={stateLogin.password}
                            onChange={(e) => handleChange(e, 'password')}
                            placeholder='Digite aqui sua senha'
                            required
                        />

                        {error === true && (
                            <Modal
                                closeModal={() => {
                                    setError(false);
                                }}
                            >
                                <ModalMessage
                                    icon={<IoIosCloseCircleOutline size={60} color='red' />}
                                    title='Acesso Negado'
                                    message='Usuário ou Senha Inválidos'
                                    button='Entendido'
                                    closeModal={() => {
                                        setError(false);
                                    }}
                                />
                            </Modal>
                        )}

                        <button className='acessar' type='submit'>
                            Acessar
                        </button>

                        <button className='senha' type='button' onClick={handleClick}>
                            Esqueci minha senha
                        </button>
                    </form>
                </div>
            )}
        </>
    );
};

export default LoginMain;
