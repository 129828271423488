import React from 'react';
// import Modal from '../Modal/Modal';
// import { useState } from 'react';
import { H2, BoxMessage, IconMessage, ModalData } from './style';


const ModalMessage = ({ icon, title, message, button, closeModal }) => {

    function handleCancel(canClose) {
        if (canClose) closeModal();
    }


    return (
        <BoxMessage closeModal={closeModal}>
            <IconMessage>{icon}</IconMessage>
            <H2>{title}</H2>
            <ModalData>{message}</ModalData>
            <button onClick={handleCancel}>{button}</button>
        </BoxMessage>
    );
};

export default ModalMessage;
