import React from 'react'
import { ReactComponent as Img0cinza } from '../../assets/icons/img0cinza.svg';
import { ReactComponent as Img1cinza } from '../../assets/icons/img1cinza.svg';
import { ReactComponent as Img2cinza } from '../../assets/icons/img2cinza.svg';
import { ReactComponent as Img3cinza } from '../../assets/icons/img3cinza.svg';
import { ReactComponent as Img4cinza } from '../../assets/icons/img4cinza.svg';
import { ReactComponent as Img5cinza } from '../../assets/icons/img5cinza.svg';
import { ReactComponent as Img6cinza } from '../../assets/icons/img6cinza.svg';

import { ReactComponent as Img0azul } from '../../assets/icons/img0azul.svg';
import { ReactComponent as Img1azul } from '../../assets/icons/img1azul.svg';
import { ReactComponent as Img2azul } from '../../assets/icons/img2azul.svg';
import { ReactComponent as Img3azul } from '../../assets/icons/img3azul.svg';
import { ReactComponent as Img4azul } from '../../assets/icons/img4azul.svg';
import { ReactComponent as Img5azul } from '../../assets/icons/img5azul.svg';
import { ReactComponent as Img6azul } from '../../assets/icons/img6azul.svg';



export const IconsTransmission = ({ position, positionActive }) => {
    let ArrayIcons = [Img0cinza, Img1cinza, Img2cinza, Img3cinza, Img4cinza, Img5cinza, Img6cinza];
    let ArrayIconsSel = [Img0azul, Img1azul, Img2azul, Img3azul, Img4azul, Img5azul, Img6azul];
    if (position === positionActive)
        return React.createElement(ArrayIconsSel[position]);
    else
        return React.createElement(ArrayIcons[position]);
}

