import React from 'react';
// import { ReactComponent as Img1cinza } from '../../assets/icons/img1cinza.svg';
// import { ReactComponent as Img1azul } from '../../assets/icons/img1azul.svg';
import { useState } from 'react';

import { IconsTransmission } from '../IconsTransmission/IconsTransmission';
import { BoxStream, Stream } from './style';

function CarouselTransmission() {
  const [positionActive, setPositionActive] = useState(0);

  return (
    <BoxStream>
      {[...Array(7)].map((e, i) => {
        return (
          <Stream key={i}>
            <div
              onClick={() => {
                setPositionActive(i);
              }}
            >
              <IconsTransmission position={i} positionActive={positionActive} />
            </div>
          </Stream>
        );
      })}
    </BoxStream>
  );
}

export default CarouselTransmission;
