import "../../assets/ModalStyle/ModalStyle.css";

import Modal from "../Modal/Modal";
import { NameValidation } from "../../utils/functions/NameValidation";
import { api } from "../../utils/api/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// import Studio from '../../pages/Studio/Studio';

const NewTransmissionModal = ({ closeModal }) => {
  const [errorName, setErrorName] = useState(false);

  const form = {
    name: "",
    password: "",
  };

  const [state, setState] = useState(form);

  const navigate = useNavigate();

  const handleChange = (e, name) => {
    if (name === "name") {
      setErrorName(false);
    }

    setState({ ...state, [name]: e.target.value });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    const NewTransmissionPayload = {
      name: e.currentTarget.name.value,
      description: "",
      password: "",
      userId: "",
    };

    const isNameValid = NameValidation(NewTransmissionPayload.name);

    if (!isNameValid) {
      setErrorName(true);
    } else {
      const retNewTransmissionData = await api.createNewTransmission(
        NewTransmissionPayload
      );
      if (retNewTransmissionData) {
        // console.log("-->", retNewTransmissionData);
        const { slug } = retNewTransmissionData;
        navigate(`/studio/${slug}`);
        return;
      }
    }

    // navigate('/studio/35');
  }

  function handleCancel() {
    closeModal();
  }

  return (
    <Modal closeModal={closeModal}>
      <div>
        <form autoComplete="off" className="form" onSubmit={handleSubmit}>
          <h2 className="h2_Modal">Nova Transmissão</h2>
          <div>
            <label htmlFor="name" className="label_Modal">
              Nome da Sala *
              {errorName === true && (
                <span className="error_span">
                  ( Nome deve ter mais que 3 caracteres)
                </span>
              )}
            </label>
          </div>
          <div>
            <input
              className="input_Modal"
              id="name"
              type="text"
              placeholder="Digite o nome da sala"
              value={state.name}
              onChange={(e) => handleChange(e, "name")}
              style={errorName? { border: "solid 1px red" }:{}}
              autoComplete="off"
            />

            
            <div>
              <label htmlFor="password" className="label_Modal">
                Senha <span className="txt_opcional">(opcional)</span>
              </label>
            </div>
            <input
              className="input_Modal"
              id="password"
              type="password"
              placeholder="Digite sue senha"
              value={state.password}
              onChange={(e) => handleChange(e, "password")}
              autoComplete="off"
            />
          </div>
          <button type="submit" className="btn_Modal">
            Iniciar
          </button>
          <button
            type="submit"
            className="btn_Cancel_Modal"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default NewTransmissionModal;
