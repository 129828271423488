import './ScheduleTransmission.css';
import { ReactComponent as SchedulePlus } from '../../assets/icons/SchedulePlus.svg';

const ScheduleTransmission = ({ addtransmissao }) => {
    return (
        <button className='transmissao2' type='button' onClick={() => addtransmissao()}>
            <SchedulePlus />
            <h3>Agendar Transmissão</h3>
        </button>
    )
}

export default ScheduleTransmission
