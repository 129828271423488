import "./Account.css";

import Aside from "../../components/Aside/Aside";
import Header from "../../components/Header/Header";
import React from "react";
import { useState } from "react";

// import { api } from '../../utils/api/api';

const Account = () => {
  const [editNameUser, setEditNameUser] = useState();
  const [editEmailUser, setEditEmailUser] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    const userEditPayload = {
      name: editNameUser,
      email: editEmailUser,
    };

    console.log(userEditPayload);
    // const userEditData = await api.updateUser(userEditPayload);
    // console.log(userEditData)
  }

  return (
    <>
      <header>
        <Header />
        <Aside />
      </header>
      <section className="containerhome">
        <div>
          <form
            autoComplete="off"
            className="form_Account"
            onSubmit={handleSubmit}
          >
            <h2 className="h2_Account">Editar Usuário</h2>
            <div>
              <label htmlFor="name" >
                Nome:
              </label>
            </div>
            <div>
              <input
                className="input_edit"
                id="name"
                name="name"
                type="text"
                autoComplete="off"
                onChange={(e) => setEditNameUser(e.target.value)}
                // defaultValue={userEditData.name}
              />
            </div>
            <div>
              <label htmlFor="email" className="label_Email">
                E-mail:
              </label>
            </div>
            <div>
              <input
                className="input_edit"
                id="email"
                name="email"
                type="text"
                autoComplete="off"
                onChange={(e) => setEditEmailUser(e.target.value)}
                // defaultValue={userEditData.email}
              />
            </div>

            <button type="submit" className="btn_Editar" onClick={() => {}}>
              Editar
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Account;
