import React, { createContext, useEffect, useState } from 'react';

import jwt_decode from "jwt-decode";

export const GlobalContext = createContext({});

export const GlobalStorage = ({ children }) => {
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    function setUserData() {
        const token = localStorage.getItem('token')
        console.log('TOKEN',token)
        if (token) {
            const decoded = jwt_decode(token);
            setEmail(decoded.email)
            setToken(token);
            console.log('TOKEN',token, decoded.email, token)
        }
        else{
            setEmail()
            setToken("");
        }
    }
    useEffect(() => {
        setUserData()
    }, []);

    return <GlobalContext.Provider value={{ isLogged: (token!=="" ? true : false), token, email, setUserData }}>{children}</GlobalContext.Provider>
}