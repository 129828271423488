import '../../assets/ModalStyle/ModalStyle.css';

import React, { useState } from 'react';

import Modal from '../Modal/Modal';

const ShareTransmissionModal = ({ closeModal }) => {

    const form = {
        linkDaTransmissao: '',
    };

    const [state, setState] = useState(form);

    const handleChange = (e, name) => {
        setState({ ...state, [name]: e.target.value });
    };

    function handleCancel(canClose) {
        if (canClose) closeModal();
    }

    return (
        <Modal closeModal={closeModal}>
            <div>
                <form autoComplete='off' className='form'>
                    <h2 className='h2_Modal'>Link da Transmissão</h2>
                    <div>
                        <label htmlFor='linkDaTransmissao' className='label_Modal'>
                            Link da Transmissão
                        </label>
                    </div>
                    <div>
                        <input
                            className='input_Modal'
                            id='linkDaTransmissao'
                            type='text'
                            value={state.linkDaTransmissao}
                            onChange={(e) => handleChange(e, 'linkDaTransmissao')}
                            required
                            autoComplete="off"
                        />
                    </div>

                    <button type='submit' className='btn_Modal'>     Iniciar
                    </button>
                    <button type='submit' className='btn_Cancel_Modal' onClick={handleCancel}>
                        Cancelar
                    </button>
                </form>
            </div>
        </Modal>
    );
};


export default ShareTransmissionModal;
