import styled from "styled-components";

export const Table= styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  color: #f2f2f2;
  width: 100%;
  padding:10px;

  th {
    padding: 5px;
  }

  td {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #ffffff;
    padding: 10px;

  
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;

    & :hover {
      color: red;
    }
  }
`;