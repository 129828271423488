import "./UserList.css";

import { AiOutlineCheck, AiOutlineUser } from "react-icons/ai";

import { BiTrash } from "react-icons/bi";
import { FormatData } from "../../utils/functions/FormatData";
import React from "react";
import { Table } from "../Table/style";

// import { Table } from "./style";


const UserList = ({ users, handleDelete }) => {
  return (
    <>
      <h2 className="list_title">Usuários</h2>
      <Table>
        <thead>
          <tr>
            <th>Nome </th>
            <th>E-mail</th>
            <th>Data</th>
            <th className="list_check">Confirmado ?</th>
            <th>Delete</th>
          </tr>
          <tr>
            <td className="list_empty"></td>
          </tr>
        </thead>
        {users.map((user) => (
          <tbody>
            <tr>
              <td className="list_icon">
                <AiOutlineUser size={20} /> {user.name}{" "}
              </td>
              <td>{user.email}</td>
              <td>{FormatData(user.createdAt)}</td>
              <td className="list_check">
                {user.password.length > 0 ? (
                  <AiOutlineCheck color="	#90EE90" />
                ) : (
                  <AiOutlineCheck color="red" />
                )}
              </td>
              <td>
                <button onClick={() => handleDelete(user.id)} key={user}>
                  <BiTrash size={15} color="white" />
                </button>
              </td>
            </tr>
          </tbody>
        ))}
      </Table>
    </>
  );
};

export default UserList;
