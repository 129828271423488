import './Users.css';

import { useEffect, useState } from 'react';

import Aside from '../../components/Aside/Aside';
import Header from '../../components/Header/Header';
import UserList from '../../components/UserList/UserList';
import { api } from '../../utils/api/api'

const Users = () => {

    const [users, setUsers] = useState([]);
    const [control, setControl] = useState(false);

    async function UsersData() {
        const users = await api.getUsers();
        setUsers(users)
    }
    // para o delete
    function handleControl() {
        setControl(!control);
    }

    async function handleDelete(id) {
        await api.deleteUser(id);
       
      }

    useEffect(() => {
        UsersData()
    }, [control]);

    return (
        <>
            <Header />
            <Aside />
            <main>
                <section className='containerhome'>

                    <UserList users={users}  handleDelete={handleDelete}/>

                </section>
            </main>
        </>
    )
}

export default Users;
