import './BoxRecoverPassword.css';

import React, { useState } from 'react';

import Input from '../Input/Input.jsx';
import { api } from '../../utils/api/api.jsx';

// import Modal from '../Modal/Modal.jsx';
// import { IoIosCloseCircleOutline } from 'react-icons/io';

function BoxRecoverPassword() {
  const form = {
    recoverPassword: '',
  };

  const [email, setEmail] = useState(form);
  const [error, setError] = useState(false);
  // const [isEmail, setIsEmail] = useState(false)

  // const handleChange = (e, name) => {
  //   setEmail({ ...email, [name]: e.target.value });
  // };

  async function handleSubmit(e) {
    e.preventDefault();
    const PasswordPayload = {
      email: e.currentTarget.email.value,
    };
    const passwordData = await api.createNewPassword(PasswordPayload);

    if (!passwordData) {
      setError(true);
    }
  }

  return (
    <>
      {/* <div className='home2' />
      <div className='container2'>
        <div className='logo2'>
          <img src={live_sim_logo_branco} alt='logo' />
        </div> */}
      <div className="boxlogin2">
        <form autoComplete="off" className="form2" onSubmit={handleSubmit}>
          <h2 className="h2_Modal2">Recuperar Senha</h2>
          <div>
            <Input
              id="email"
              label="E-mail"
              type="text"
              value={email}
              setValue={setEmail}
              placeholder="Digite aqui seu e-mail cadastrado"
              required
              autoComplete="off"
            />
            {/* <label className='label_Modal2'>
              E-mail
            </label>
          </div>
          <div>
          <input
              className='input_Modal2'
              id='recoverPassword'
              type='text'
              placeholder='Digite aqui seu e-mail cadastrado'
              value={email.recoverPassword}
              onChange={(e) => handleChange(e, 'nomeDaSala')}
              required
            /> */}
          </div>

          {/* {isEmail === true && (<Modal
                closeModal={() => {
                  setError(false);
                }}
              >
                <div className='Error_Message'>
                  <div className='Outline_Icon'>
                    <IoIosCloseCircleOutline size={40} color='red' />
                  </div>
                  <h2 className='Error_h2'>Acesso negado</h2>
                  <p className='Error_Data'>Usuário ou Senha inválidos</p>
                </div>
              </Modal>)} */}

          <button type="submit" className="acessar2">
            Enviar link de redefinição
          </button>
        </form>
      </div>
      {/* </div> */}
    </>
  );
}

export default BoxRecoverPassword;
