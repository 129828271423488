import React, { forwardRef } from 'react';

import styled from 'styled-components';

export const VideoContainer = styled.div`
  width: var(--width);
  height: var(--height);
  background-color:transparent;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
 
`;

export const Video = styled.video`
  height: 100%;
  width: 100%;
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */  
  margin: 0;
 

 
`;

export const LocalVideo = forwardRef((props, ref) => {
  return (
    <VideoContainer>
      {/* <Print>{props}</Print> */}
      {/* <VoiceVisualizer id="local" /> */}
      <Video {...props} ref={ref} />
    </VideoContainer>
  );
});
