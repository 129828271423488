import './ShareTransmission.css';
import { ReactComponent as Share } from '../../assets/icons/Share.svg';
import React from 'react';

const ShareTransmission = ({ compartilharTransmissao }) => {
    return (
        <button className='share_Transmission' type='button' onClick={() => compartilharTransmissao()}>
            <div className='iconetransmitir'>
                <Share />
                <p className='compartilhar'>Compartilhar</p>
            </div>
        </button>
    );
};

export default ShareTransmission;
