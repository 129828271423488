import Header from '../../components/Header/Header';
import Aside from '../../components/Aside/Aside';
import './Config.css';

const Config = () => {
    return (
        <>
            <Header />
            <Aside />
            <main>
                <section className='containerhome'>
                    <h1 className='titulo1'>Configurações</h1>
                </section>
            </main>
        </>
    )
}

export default Config
