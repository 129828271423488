import './Overlay.css';
//tirei do parametro o overlayClick e tb onClick={() => overlayClick()} da div
const Overlay = ({ children }) => {
    return (
        <div className='overlay'  >
            {children}
        </div>
    )
}

export default Overlay;
