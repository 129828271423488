import './Header.css';

import Select from './Select/Select';
import live_sim_logo_branco from '../../assets/logo/live_sim_logo_branco.png';

const Header = () => {
    return (
        <>
            <header className='header'>
                <nav>
                    <div className='Logo_Header'>
                        <img src={live_sim_logo_branco} alt='logo' />
                    </div>
                    <div className='select_logout'>
                        <Select options={['Felipe Alves', 'Tiago Braga']} />
                       
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Header;
