import './HeaderStudio.css';

import { NavLink } from 'react-router-dom';
import { ReactComponent as Return } from '../../assets/icons/Return.svg';
import ShareTransmission from '../ShareTransmission/ShareTransmission';
import ShareTransmissionModal from '../ShareTransmissionModal/ShareTransmissionModal';
import live_sim_logo_branco from '../../assets/logo/live_sim_logo_branco.png';
import { useState } from 'react';

const HeaderStudio = ({ nameTransmission }) => {
    const [addModal, setAddModal] = useState(false);

    return (
        <>
            <header>
                <div className='geral'>
                    <div className='divesq'>
                        <div className='voltar'>
                            <NavLink to='/'>
                                <Return />
                            </NavLink>
                        </div>
                        <img src={live_sim_logo_branco} alt='logo' />
                        <input
                            className='inputheader'
                            type='text'
                            placeholder={nameTransmission}
                        />
                    </div>
                    <div className='header_studioLogout'>
                        <ShareTransmission
                            compartilharTransmissao={() => setAddModal(true)}
                        />
                        {addModal && (
                            <ShareTransmissionModal closeModal={() => setAddModal(false)} />
                        )}
                       
                    </div>
                </div>
            </header>
        </>
    );
};

export default HeaderStudio;
