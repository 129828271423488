import './NewTransmission.css';
import { ReactComponent as CamPlus } from '../../assets/icons/CamPlus.svg';

const NewTransmission = ({ novaTransmissao }) => {
    return (
        <button
            className='transmissao'
            type='button'
            onClick={() => novaTransmissao()}
        >
            <CamPlus />

            <h3>Transmitir Agora</h3>
        </button>
    );
};

export default NewTransmission;
