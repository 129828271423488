import "./Select.css";
import {} from "react";

import { useContext, useState } from "react";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { ReactComponent as More } from "../../../assets/icons/more-vertical.svg";
import { ReactComponent as Usericon } from "../../../assets/icons/Usericon.svg";
import { useNavigate } from "react-router-dom";

const Select = () => {
  const { email, setUserData } = useContext(GlobalContext);
  const [canShowModal, setCanShowModal] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem("token");
    setUserData();
    navigate("/logout");      
  }

  return (
    <>
      <div className="select">
        <div className="iconuser">
          <Usericon />
        </div>
        <input readOnly={true} className="selectuser" value={email} />
      </div>

      <div className="dropdown">
        <button className="more" onClick={handleOpen}>
          <More />
        </button>
        {open ? (
          <ul className="menu_drop">
            <li className="menu-item1">
              <button
                onClick={() => {
                  navigate("/account");
                }}
              >
                Editar
              </button>
            </li>
            <li className="menu-item2">
              <button onClick={handleLogout}>Logout</button>
            </li>
          </ul>
        ) : null}
      </div>

      {/* <ul id="nav">
        <li className="lista_geral">
          <label for="sub1">
            <More />
          </label>
          <input id="sub1" type="checkbox" />
          <ul>
            <li
              className="lista_um"
              onClick={() => {
                navigate("/account");
              }}
            >
              Conta
            </li>

            <li className="lista_dois" onClick={handleLogout}>
              Logout
            </li>
          </ul>
        </li>
      </ul> */}

      {/* <div className="dropdown">
          <button className="dropbtn">
            <More />
          </button>

          <div className="dropdown-content">
            <ul className="bordas">
              
              <li className="lista_um" onClick={() => {navigate("/account")}}>
                Conta
              </li>

              <li className="lista_dois" onClick={handleLogout}>
                Logout
              </li>
            </ul>
          </div>
        </div> */}
    </>
  );
};

export default Select;
