import './ConfigParticipant.css';

import { ReactComponent as More } from '../../assets/icons/more-vertical.svg';

function ConfigParticipant({ configParticipant }) {
  return (
    <div className="Add_Participantes">
      <button
        className="trespontos"
        onClick={() => {
          configParticipant();
        }}
      >
        <More />
      </button>
    </div>
  );
}

export default ConfigParticipant;
