import './Home.css';

import AddUsers from '../../components/AddUsers/AddUsers';
import AddUsersModal from '../../components/AddUsersModal/AddUsersModal';
import Aside from '../../components/Aside/Aside';
import Header from '../../components/Header/Header';
import NewTransmission from '../../components/NewTransmission/NewTransmission';
import NewTransmissionModal from '../../components/NewTransmissionModal/NewTransmissionModal';
import { ReactComponent as Schedule } from '../../assets/icons/Schedule.svg';
import ScheduleTransmission from '../../components/ScheduleTransmission/ScheduleTransmission';
import ScheduleTransmissionModal from '../../components/ScheduleTransmissionModal/ScheduleTransmissionModal';
import { useState } from 'react';

const Home = () => {
    const [canShowModal, setCanShowModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [addShowModal, setAddShowModal] = useState(false);
    return (
        <>
            <Header />

            <Aside />

            <main>
                <section className='containerhome'>
                    {/* <HomeContainer> */}

                    <h1 className='titulo1'>Nova Transmissão</h1>

                    <div className='boxtransmissao'>
                        <NewTransmission novaTransmissao={() => setCanShowModal(true)} />
                        {canShowModal && (
                            <NewTransmissionModal closeModal={() => setCanShowModal(false)} />
                        )}

                        <ScheduleTransmission
                            addtransmissao={() => setAddShowModal(true)}
                        />
                        {addShowModal && (
                            <ScheduleTransmissionModal
                                closeModal={() => setAddShowModal(false)}
                            />
                        )}

                        <AddUsers adduser={() => setShowModal(true)} />
                        {showModal && (
                            <AddUsersModal closeModal={() => setShowModal(false)} />
                        )}
                    </div>

                    <h2 className='titulo2'>Transmissões Agendadas</h2>
                    <div className='boxagendadas'>
                        <div className='transmissao2'>
                            <div className='icone'>
                                <Schedule />
                                <h3>14/11/2022</h3>
                            </div>

                            <h3>Live Segunda</h3>
                        </div>
                    </div>
                    {/* </HomeContainer> */}
                </section>
            </main>
        </>
    );
};

export default Home;
