import "./Modal.css";

import Overlay from "../../components/Overlay/Overlay";
import { useEffect } from "react";

// tirei overlayClick={closeModal} do component para ele fechar clicando fora no overlay
const Modal = ({ children, closeModal }) => {
   
  const handleClick = (e) => {
    e.stopPropagation();
    closeModal();
  };


  const onKeyDown = (event) => {
    if (event.keyCode === 27) {
      closeModal();
    }
  };
  
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <Overlay>
      <div className="modal">
        <span
          className="modal_close"
          onClick={(e) => {
            handleClick(e, true);
          }}
        >
          X
        </span>
        <div className="modal_body">{children}</div>
      </div>
    </Overlay>
  );
};

export default Modal;
