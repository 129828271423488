import "./Studio.css";

import {
  useCalculateVideoLayout,
  useCreateMediaStream,
  useStartPeerSession,
} from "../../hooks";
import { useRef, useState } from "react";

import CarouselTransmission from "../../components/CarouselTransmission/CarouselTransmission";
import ConfigParticipant from "../../components/ConfigParticipant/ConfigParticipant";
import ConfigParticipantModal from "../../components/ConfigParticipantModal/ConfigParticipantModal";
import HeaderStudio from "../../components/HeaderStudio/HeaderStudio";
import { LocalVideo } from "../../components/LocalVideo";
import { ReactComponent as Mic } from "../../assets/icons/mic.svg";
import { ReactComponent as Pass } from "../../assets/icons/Pass.svg";
import { ReactComponent as Radio } from "../../assets/icons/radio.svg";
import { ReactComponent as UsersPlus } from "../../assets/icons/UsersPlus.svg";
import { ReactComponent as Xis } from "../../assets/icons/X.svg";
import { useParams } from "react-router-dom";

// import { ReactComponent as More } from '../../assets/icons/more-vertical.svg';

const Studio = () => {
  const params = useParams();
  const localVideoRef = useRef();

  const [showConfig, setShowConfig] = useState(false);
  const { room } = useParams();

  const userMediaStream = useCreateMediaStream(localVideoRef);
  const { connectedUsers, shareScreen, cancelScreenSharing, isScreenShared } =
    useStartPeerSession(room, userMediaStream, localVideoRef);

  return (
    <>
      <HeaderStudio nameTransmission={params.id} />
      <main>
        <div className="top">
          <div className="previsualizar">Pré-Visualizar</div>
          <div className="iconetransmitindo">
            <Radio />
          </div>
          <div className="transmitindo">Transmitindo</div>
        </div>

        <div className="conteudo">
          <div className="boxprevisualizacao"></div>

          <div className="boxescolhas">
            <div className="utilizar">
              <Pass />
            </div>
            <p className="cena">Usar Cena</p>
            <div className="fechar">
              <Xis />
            </div>
            <p className="finalizar">Finalizar</p>
          </div>

          <div className="boxtransmitindo"></div>
        </div>

        <CarouselTransmission />

        <div className="previsualizar">
          <p>Participantes</p>
        </div>

        <div className="participantes">
          <div className="boxum">
            <UsersPlus />
            <h4>Convidar Participante</h4>
          </div>

          <div className="boxdois">
            
            <LocalVideo ref={localVideoRef} autoPlay playsInline muted />
            
            <div className="trespontos">              
            <ConfigParticipant configParticipant={() => setShowConfig(true)} />
            {showConfig && (
              <ConfigParticipantModal closeModal={() => setShowConfig(false)} />
            )}
            </div>

            <div className="bordaazul">
              <p className="parti">Você</p>
              <div className="mic">
                <Mic />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Studio;
