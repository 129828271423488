import './ConfigParticipantModal.css';

import { ReactComponent as Cam } from '../../assets/icons/Cam.svg';
import { ReactComponent as CamBlue } from '../../assets/icons/CamBlue.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as DownloadWhite } from '../../assets/icons/downloadwhite.svg';
import { ReactComponent as Mic } from '../../assets/icons/mic.svg';
import { ReactComponent as MicBlue } from '../../assets/icons/MicBlue.svg';
import Modal from '../Modal/Modal';
import { ReactComponent as PlayCircle } from '../../assets/icons/PlayCircle.svg';
import { ReactComponent as PlayCircleWhite } from '../../assets/icons/PlayCircleWhite.svg';
import { useState } from 'react';

function ConfigParticipantModal({ closeModal }) {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const [isActiveDois, setIsActiveDois] = useState(false);
  const handleClickDois = () => {
    setIsActiveDois((current) => !current);
  };

  const [isActiveThree, setIsActiveThree] = useState(false);
  const handleClickThree = () => {
    setIsActiveThree((current) => !current);
  };

  const [isActiveFour, setIsActiveFour] = useState(false);
  const handleClickFour = () => {
    setIsActiveFour((current) => !current);
  };

  function handleCancel(canClose) {
    if (canClose) closeModal();
  }

  return (
    <Modal closeModal={closeModal}>
      <div className="Modal_Config">
        <h2 className="h2_Modal">Configurações do participante</h2>
        <div className="Imagem_Participante" />

        <div className="Linha_Um">
          <button
            className="borda_button"
            style={{
              backgroundColor: isActive ? '#009ef7' : '',
            }}
            onClick={handleClick}
          >
            {isActive ? <DownloadWhite /> :<Download />  }

            <h3
              className="h3_Modal"
              style={{
                color: isActive ? '#ffffff' : '',
              }}
            >
              Gravar pra mim
            </h3>
          </button>

          <button
            className="borda_button"
            style={{
              backgroundColor: isActiveDois ? '#009ef7' : '',
            }}
            onClick={handleClickDois}
          >
            {isActiveDois ?  <Cam /> : <CamBlue /> }
            <h3
              className="h3_Modal"
              style={{
                color: isActiveDois ? '#ffffff' : '',
              }}
            >
              Gravar Remoto
            </h3>
          </button>
        </div>

        <div className="Linha_Um">
          <button
            className="borda_button"
            style={{ backgroundColor: isActiveThree ? '#009ef7' : '' }}
            onClick={handleClickThree}
          >
            {isActiveThree ? <Mic/> : <MicBlue />}
            <h3
              className="h3_Modal"
              style={{ color: isActiveThree ? '#ffffff' : '' }}
            >
              Audio
            </h3>
          </button>
          <button
            className="borda_button"
            style={{ backgroundColor: isActiveFour ? '#009ef7' : '' }}
            onClick={handleClickFour}
          >
            {isActiveFour ? <PlayCircleWhite /> : <PlayCircle />}
            <h3
              className="h3_Modal"
              style={{ color: isActiveFour ? '#ffffff' : '' }}
            >
              Video
            </h3>
          </button>
        </div>
        <button className="btn_Cancel_Modal_Config" onClick={handleCancel}>
          Cancelar
        </button>
      </div>
    </Modal>
  );
}

export default ConfigParticipantModal;
