import React, { useContext } from 'react';

import { GlobalContext } from '../contexts/GlobalContext';
import OtherRoutes from './OtherRoutes';
import SignRoutes from './SignRoutes';

// import { useAuth } from '../contexts/auth';




const Routes = () => {
  const { isLogged } = useContext(GlobalContext)
  console.log('esta logado?', isLogged)
  return isLogged ? <SignRoutes /> :<OtherRoutes />;
};

export default Routes;
