import { GlobalStorage } from './contexts/GlobalContext';
import Routes from './routes';

import './App.css';

function App() {
    return (
        <GlobalStorage>
            <Routes />
        </GlobalStorage>
    );
}

export default App;
