import './Input.css';

const Input = ({ id, label, type, onChange, value, ...props }) => {
    return (
        <>
            <label htmlFor={id}>{label}</label>
            <input
                id={id}
                name={id}
                value={value}
                type={type}
                onChange={onChange}
                {...props}
            />
        </>
    );
};

export default Input;
