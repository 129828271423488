import axios from 'axios';

axios.defaults.baseURL = 'https://apilivesim.fpinfo.com.br';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (config) {
        return config;
    },
    function (error) {
        console.log('Error Axios', error.response)
        // if (error.response.status === 401) {
        //     if (localStorage.getItem('token')) localStorage.removeItem('token');
        // }
    }
);

export const api = {
    login: async (payload) => {
        try {
            const response = await axios.post('/auth/login', payload);
            console.log(response.data.access_token);
            localStorage.setItem('token', response.data.access_token);
            return response.data;
        } catch (e) {
            console.error(e);
        }
    },

    // Crud Usuário
    getUsers: async () => {
        try {
            const response = await axios.get('/users');
            return response.data;
        } catch (err) {
            alert(err.message);
        }
    },

    createUser: async (payload) => {
        try {
            const response = await axios.post('/users', payload);
            return response.data;
        } catch (err) {
            alert(err.message);
        }
    },

    updateUser: async (payload) => {
        try {
          const response = await axios.patch("/users/" + payload.id, {
            name: payload.name,
            email: payload.email           
          });
          return response.data;
        } catch (err) {
          alert(err.message);
        }
      },
      
    deleteUser: async (payload) => {
        try {
            const response = await axios.delete(`/users/${payload}`);
            return response.data;
        } catch (err) {
            alert(err.message);
        }
    },

    //Crud Transmission

    createNewTransmission: async (payload) => {
        try {
            const response = await axios.post('/streamings', payload);
            return response.data;
        } catch (err) {
            alert(err.message);
        }
    },

    //Crud ScheduleTransmission

    createDateTransmission: async (payload) => {
        try {
            const response = await axios.post('/streamings', payload);
            return response.data;
        } catch (err) {
            alert(err.message);
        }
    },

    getStreamings: async () => {
        try {
            const response = await axios.get('/streamings');
            return response.data;
        } catch (err) {
            alert(err.message);
        }
    },

};
