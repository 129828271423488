import './Transmissions.css';

import { useEffect, useState } from 'react';

import Aside from '../../components/Aside/Aside';
import Header from '../../components/Header/Header';
import ScheduleTransmissionList from '../../components/ScheduleTransmissionList/ScheduleTransmissionList';
import { api } from '../../utils/api/api'

const Transmissions = () => {

    const [streamings, setStreamings] = useState([]);

    async function streamingData() {
        const streamings = await api.getStreamings();
        setStreamings(streamings)
    }

    useEffect(() => {
        streamingData();
    }, []);
    
    return (
        <>
            <Header />
            <Aside />
            <main>
                <section className='containerhome'>
                    <ScheduleTransmissionList streamings={streamings}/>
                </section>

            </main>
        </>
    )
}

export default Transmissions;
