import styled from "styled-components";

export const BoxMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 312px;
    height: 45px;
    background: #009ef7;
    border-radius: 6px;
    border: none;
    margin-left: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    margin: 10px;
  }
`;

export const IconMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

export const H2 = styled.h2`
  justify-content: center;
  width: 323px;
  height: 45px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  margin: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1e222b;
  color: #1e222b;
`;

export const ModalData = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 328px; */
  /* height: 53px; */
  /* left: 498px;
  top: 412px; */
  /* font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: red; */
  width: 400px;
  height: 53px;
  /* left: 498px;
  top: 412px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #7d7d7d;
  margin: 10px;
`;
